// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/AppRating.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/AppRating.tsx");
}
// REMIX HMR END

import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import styles from './AppRating.module.css';
import { Text } from '../Text';
export function AppRating({
  ratings,
  stars
}) {
  _s();
  const {
    t
  } = usePrefixedTranslation('index');
  return <div className={styles.appRating}>
      <Text size="sm">{t('ratings', {
        count: ratings
      })}</Text>
      <Text size="xxxl" className={styles.appRatingNumber}>
        {stars}
      </Text>
      <div aria-hidden={true} className={styles.stars}>
        <img src="/img/star.svg" />
        <img src="/img/star.svg" />
        <img src="/img/star.svg" />
        <img src="/img/star.svg" />
        <img src="/img/star.svg" />
      </div>
    </div>;
}
_s(AppRating, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = AppRating;
var _c;
$RefreshReg$(_c, "AppRating");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;