// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/RedeemCoupon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/RedeemCoupon.tsx");
}
// REMIX HMR END

import { TextInput } from '~/components/form/TextInput';
import { FormField } from '~/components/form/FormField';
import { Label } from '~/components/form/Label';
import { Button } from '~/components/Button';
import { Form, Link } from '@remix-run/react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './RedeemCoupon.module.css';
import { Text } from '../Text';
import { WarningIcon } from '../icons/WarningIcon';
import { DownArrowIcon } from '../icons/DownArrowIcon';
import { CouponIcon } from '../icons/CouponIcon';
import { useContext } from 'react';
import { AnalyticsContext } from '../../AnalyticsContext';
function CouponApplied({
  coupon
}) {
  _s();
  const {
    t
  } = useTranslation();
  return <div className={styles.couponApplied}>
      <CouponIcon lineClassName={styles.couponIconLine} glyphClassName={styles.couponIconGlyph} />

      <Text size="lg" weight={500} className={styles.couponAppliedText}>
        <Trans t={t} i18nKey="index.couponApplied" values={{
        coupon: coupon.code
      }} components={[<span className={styles.couponAppliedCode} />]} />

      </Text>
      <Link to={'/'} preventScrollReset={true} className={styles.removeCoupon}>
        <Text size="sm" as="span">
          {t('common.remove')}
        </Text>
      </Link>
    </div>;
}
_s(CouponApplied, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = CouponApplied;
function handleToggle(trackEvent) {
  return e => {
    if (e.target.open) {
      trackEvent('Redeem Coupon Open');
    } else {
      trackEvent('Redeem Coupon Close');
    }
  };
}
function ApplyCoupon({
  coupon,
  valid,
  loading
}) {
  _s2();
  const {
    t
  } = useTranslation();
  const {
    trackEvent
  } = useContext(AnalyticsContext);
  return <details className={styles.applyCoupon} open={!!coupon} onToggle={handleToggle(trackEvent)}>
      <summary className={styles.applyCouponSummary}>
        <CouponIcon lineClassName={styles.couponIconLine} glyphClassName={styles.couponIconGlyph} />

        <Text size="lg" weight={500} className={styles.applyCouponSummaryText}>
          {t('index.redeemCoupon')}
        </Text>

        <DownArrowIcon className={styles.arrowIcon} />
      </summary>

      <Form method="GET" className={styles.form} preventScrollReset={true} onSubmit={e => trackEvent('Apply Coupon', {
      props: {
        coupon: new FormData(e.target).get('coupon')
      }
    })}>

        {valid ? null : <div className={styles.invalidCoupon}>
            <WarningIcon className={styles.warningIcon} />
            <Text weight={500}>{t('index.couponInvalid')}</Text>
          </div>}

        <FormField className={styles.couponField}>
          <Label htmlFor="coupon" className={styles.couponLabel}>
            {t('index.couponCode')}
          </Label>
          <TextInput name="coupon" id="coupon" data-testid="coupon-input" autoComplete="off" autoCorrect="off" autoCapitalize="off" className={styles.couponInput} defaultValue={coupon?.code} key={coupon?.code} invalid={!valid} />

        </FormField>

        <Button className={styles.formButton} loading={loading}>
          {t('common.apply')}
        </Button>
      </Form>
    </details>;
}
_s2(ApplyCoupon, "gKhnLBEAL5riPP3P8WgfBv7G19o=", false, function () {
  return [useTranslation];
});
_c2 = ApplyCoupon;
export function RedeemCoupon(props) {
  return props.valid && !props.loading && props.coupon ? <CouponApplied coupon={props.coupon} /> : <ApplyCoupon {...props} />;
}
_c3 = RedeemCoupon;
var _c, _c2, _c3;
$RefreshReg$(_c, "CouponApplied");
$RefreshReg$(_c2, "ApplyCoupon");
$RefreshReg$(_c3, "RedeemCoupon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;