// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Badge.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Badge.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import styles from './Badge.module.css';
import { Text } from './Text';
export function Badge({
  text,
  theme
}) {
  return <Text as="span" weight={500} className={clsx(styles.badge, theme && styles[theme])}>
      {text}
    </Text>;
}
_c = Badge;
var _c;
$RefreshReg$(_c, "Badge");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;