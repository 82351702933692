// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/page/PageTitle.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/page/PageTitle.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import { Heading } from '../Heading';
import styles from './PageTitle.module.css';
export function PageTitle({
  children,
  className,
  centred = true
}) {
  return <Heading level={2} className={clsx(styles.title, className, centred && styles.centred)}>
      {children}
    </Heading>;
}
_c = PageTitle;
var _c;
$RefreshReg$(_c, "PageTitle");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;