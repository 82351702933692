// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/SelectPlanForm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/SelectPlanForm.tsx");
}
// REMIX HMR END

import { useSignal } from '@preact/signals-react';
import { getPaymentSummary } from 'lib/get-payment-summary';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Plans } from '../plans/Plans';
import { Text } from '../Text';
import styles from './SelectPlanForm.module.css';
import { Form, useFetcher } from '@remix-run/react';
import { RedeemCoupon } from '~/components/index-route/RedeemCoupon';
import { PlanSkeleton } from '../plans/PlanSkeleton';
import { Message } from '~/components/Message';
import { useContext, useEffect } from 'react';
import { AnalyticsContext } from '../../AnalyticsContext';
function SelectPlan({
  selectedPlan,
  plans,
  coupon,
  loadingPlans
}) {
  _s();
  const {
    t,
    i18n
  } = useTranslation();
  const paymentSummary = getPaymentSummary(t, i18n.language, selectedPlan.value);
  const appliedCouponIsValid = coupon.value && !loadingPlans.value ? plans.value.some(({
    coupon
  }) => !!coupon?.code) : true;
  const {
    trackEvent
  } = useContext(AnalyticsContext);
  return <div className={styles.selectPlans}>
      {loadingPlans.value && !plans.value.length ? <>
          <PlanSkeleton />
          <PlanSkeleton />
        </> : <Form method="POST" id="select-plan" onSubmit={() => trackEvent('Select Plan', {
      props: {
        plan: selectedPlan.value?.id,
        interval: selectedPlan.value?.interval,
        freeTrialDays: selectedPlan.value?.freeTrialDays,
        coupon: coupon.value
      }
    })}>

          <input type="hidden" name="coupon" value={coupon.value} />

          <Plans plans={plans.value} onPlanSelect={selectedPlanId => {
        selectedPlan.value = plans.value.find(({
          id
        }) => id === selectedPlanId);
      }} initiallySelectedPlan={selectedPlan.value?.id} />

        </Form>}

      <RedeemCoupon coupon={selectedPlan.value?.coupon} valid={appliedCouponIsValid} loading={loadingPlans.value} />

      <div className={styles.continue}>
        <Button block={true} form="select-plan" disabled={loadingPlans.value} data-testid="create-account-button">
          {t('index.createAccount')}
        </Button>
      </div>

      <div>
        {selectedPlan.value ? <Text as="p" size="md" className={styles.planPriceDetails} data-testid="payment-summary">
            {selectedPlan.value?.upfrontPaymentRequired && selectedPlan.value.coupon?.type !== 'b2b' ? t('common.plan.paymentSummaryAutoRenews', {
          paymentSummary
        }) : paymentSummary}
          </Text> : null}
      </div>
    </div>;
}
_s(SelectPlan, "BjgXiYW1j6P2D4QNoT4iZwqUyOA=", false, function () {
  return [useTranslation];
});
_c = SelectPlan;
function hasPlans(plans) {
  return plans.some(plan => plan !== null);
}
export function SelectPlanForm({
  selectedPlan,
  coupon
}) {
  _s2();
  const {
    t
  } = useTranslation();
  const plansFetcher = useFetcher();
  const loadingPlans = useSignal(true);
  const plans = useSignal([]);
  useEffect(() => {
    if (plansFetcher.state === 'idle') {
      if (!plansFetcher.data) {
        plansFetcher.load(`/api/get-available-plans?coupon=${coupon.value}`);
      } else if (!plansFetcher.data.error && hasPlans(plansFetcher.data.plans)) {
        plans.value = plansFetcher.data.plans;
        /**
         * Determines which plan should be selected when the user initially
         * visits the page, in the following order of preference:
         *   - Plan with promo code applied
         *   - Yearly plan
         *   - First option
         */
        selectedPlan.value = plans.value.find(({
          coupon
        }) => !!coupon?.code) || plans.value.find(({
          interval
        }) => interval === 'year') || plans.value[0];
      }
    }
    loadingPlans.value = ['submitting', 'loading'].includes(plansFetcher.state);
  }, [plansFetcher]);
  useEffect(() => {
    plansFetcher.load(`/api/get-available-plans?coupon=${coupon.value}`);
  }, [coupon.value]);
  return plansFetcher.data?.error ? <Message type="error" title={t('common.error')} message={t('index.unableToFetchPlansError')} /> : <SelectPlan selectedPlan={selectedPlan} coupon={coupon} plans={plans} loadingPlans={loadingPlans} />;
}
_s2(SelectPlanForm, "cqQ7XMzjF6ItGqkuaazkeFM/LxQ=", false, function () {
  return [useTranslation, useFetcher, useSignal, useSignal];
});
_c2 = SelectPlanForm;
var _c, _c2;
$RefreshReg$(_c, "SelectPlan");
$RefreshReg$(_c2, "SelectPlanForm");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;