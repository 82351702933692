// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/FeatureList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/FeatureList.tsx");
}
// REMIX HMR END

import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import { Text } from '../Text';
import styles from './FeatureList.module.css';
export function FeatureList() {
  _s();
  const {
    t
  } = usePrefixedTranslation('index');
  const featuresList = t('features', {
    returnObjects: true
  });
  const features = Array.isArray(featuresList) ? featuresList : [featuresList];
  return <ul className={styles.features}>
      {features.map((feature, i) => <Text key={i} as="li" size="lg" className={styles.feature}>
          {feature}
        </Text>)}
    </ul>;
}
_s(FeatureList, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = FeatureList;
var _c;
$RefreshReg$(_c, "FeatureList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;