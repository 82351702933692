// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/AwardsList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/AwardsList.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import { AppRating } from './AppRating';
import styles from './AwardsList.module.css';
import { useTranslation } from 'react-i18next';
export function AwardsList({
  className
}) {
  _s();
  const {
    t
  } = useTranslation();
  const appStoreAltText = t('index.appStoreEditorsChoice');
  const playStoreAltText = t('index.playStoreBestApp');
  return <div className={clsx(styles.awardsList, className)}>
      <img src="img/app-store-editor-choice.svg" className={styles.award} alt={appStoreAltText} />
      <div className={styles.separator} />
      <div className={styles.award}>
        <AppRating ratings={33} stars={4.8} />
      </div>
      <div className={styles.separator} />
      <img src={'img/play-store-best-app.svg'} className={styles.award} alt={playStoreAltText} />
    </div>;
}
_s(AwardsList, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = AwardsList;
var _c;
$RefreshReg$(_c, "AwardsList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;