// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/PlanList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/PlanList.tsx");
}
// REMIX HMR END

import { SelectPlanForm } from './SelectPlanForm';
import styles from './PlanList.module.css';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text';
export function PlanList({
  className,
  coupon,
  selectedPlan,
  hasError
}) {
  _s();
  const {
    t
  } = useTranslation();
  return <div className={className}>
      {hasError ? <Text className={styles.noPlanSelectedError} size="lg" as="p">
          {t('index.noPlanSelectedError')}
        </Text> : null}

      <SelectPlanForm selectedPlan={selectedPlan} coupon={coupon} />
    </div>;
}
_s(PlanList, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = PlanList;
var _c;
$RefreshReg$(_c, "PlanList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;