// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/index-route/FeatureCarousel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/index-route/FeatureCarousel.tsx");
}
// REMIX HMR END

import { useTranslation } from 'react-i18next';
import { Carousel } from '../Carousel';
import styles from './FeatureCarousel.module.css';
import clsx from 'clsx';
import { Text } from '../Text';
import OptimisedImage from '../OptimisedImage';
export default function FeatureCarousel({
  className
}) {
  _s();
  const {
    t
  } = useTranslation();
  const featureList = t('featureList', {
    returnObjects: true
  });
  const features = Array.isArray(featureList) ? featureList : [featureList];
  return <Carousel itemHeight="25.239rem" itemWidth="11.125rem" className={clsx(styles.featuresCarousel, className)}>
      {features.map(feature => <div key={feature.title} className={clsx(styles.featureItem, !feature.title && styles.imageOnly)}>
          <Text size="sm" className={styles.carouselTitle}>
            {feature.title}
          </Text>
          <OptimisedImage imageDetails={feature} className={styles.carouselImage} />
        </div>)}
    </Carousel>;
}
_s(FeatureCarousel, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = FeatureCarousel;
var _c;
$RefreshReg$(_c, "FeatureCarousel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;