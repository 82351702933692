import { Plan } from './stripe/get-plan'
import Dinero from 'dinero.js'

function getDailyPrice(plan: Plan) {
  const amount = Dinero({ amount: plan.amount.total, currency: plan.currency })

  switch (plan.interval) {
    case 'day':
      return amount.getAmount()
    case 'week':
      return amount.divide(7).getAmount()
    case 'month':
      return amount.divide(30).getAmount()
    case 'year':
      return amount.divide(365).getAmount()
    default:
      throw new Error(`Unsupported plan interval period: ${plan.interval}`)
  }
}

/**
 * Determines whether a plan should receive the "best value" badge. A plan is
 * deemed to be the best value based on its price evaluated over a daily period.
 */
export function planIsBestValue(plan: Plan, plans: Plan[]) {
  const sortedPlans = [...plans].sort((a, b) => getDailyPrice(a) - getDailyPrice(b))

  return sortedPlans[0]?.id === plan.id
}
