// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/pageHeader/PageHeaderTitle.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/pageHeader/PageHeaderTitle.tsx");
}
// REMIX HMR END

import styles from './PageHeaderTitle.module.css';
import clsx from 'clsx';
export function PageHeaderTitle({
  className,
  children
}) {
  return <div className={clsx(styles.title, className)}>{children}</div>;
}
_c = PageHeaderTitle;
var _c;
$RefreshReg$(_c, "PageHeaderTitle");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;