// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/plans/PlanSkeleton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/plans/PlanSkeleton.tsx");
}
// REMIX HMR END

import styles from './PlanSkeleton.module.css';
export function PlanSkeleton() {
  return <div role="presentation" className={styles.skeleton}>
      <div className={styles.title}></div>
      <div className={styles.price}></div>
    </div>;
}
_c = PlanSkeleton;
var _c;
$RefreshReg$(_c, "PlanSkeleton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;