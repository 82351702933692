// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/plans/Plan.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/plans/Plan.tsx");
}
// REMIX HMR END

import { amountToLocaleString } from 'lib/stripe/amount-to-locale-string';
import { Badge } from '../Badge';
import { Heading } from '../Heading';
import styles from './Plan.module.css';
import { PlanPrice } from './PlanPrice';
import { Text } from '../Text';
import Dinero from 'dinero.js';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
export function Plan({
  plan,
  onSelect,
  selected = false,
  isBestValue = false
}) {
  _s();
  const {
    t,
    i18n
  } = usePrefixedTranslation('common');
  return <li key={plan.id}>
      <div className={styles.plan}>
        <label className={styles.planDetails} data-testid="plan" aria-label={t('plan.label', {
        name: t(`plan.interval.${plan.interval}`),
        amount: amountToLocaleString(plan.amount.total, plan.currency, i18n.language)
      }) ?? undefined}>

          <div className={styles.body}>
            <div className={styles.badges}>
              {isBestValue && !plan.coupon?.code ? <Badge text={t('plan.bestValue')} theme="zest" /> : null}
              {plan.coupon?.code ? <Badge text={t('plan.discount', {
              name: plan.coupon.name || plan.coupon.code
            })} theme="zest" /> : null}
            </div>

            <Heading as="span" level={3} className={styles.heading} data-testid="plan-name">
              {t(`plan.interval.${plan.interval}`)}
            </Heading>

            <PlanPrice plan={plan} />

            {plan.interval === 'year' && plan.upfrontPaymentRequired ? <Text className={styles.pricePerMonth} size="lg">
                {t('plan.pricePerMonth', {
              amount: amountToLocaleString(Dinero({
                amount: plan.amount.total,
                currency: plan.currency
              }).divide(12).getAmount(), plan.currency, i18n.language)
            })}
              </Text> : null}
          </div>

          <input type="radio" name="plan" value={plan.id} checked={selected} onChange={onSelect ? () => onSelect(plan.id) : undefined} required />

          {!!plan.freeTrialDays && <div className={styles.freeTrialMessageContainer}>
              <Text size="sm" className={styles.freeTrialMessage}>
                {t('plan.freeTrialEndingMessage')}
              </Text>
            </div>}
        </label>
      </div>
    </li>;
}
_s(Plan, "3dEaX9VwcQTsutumYNit7tHi3ms=", false, function () {
  return [usePrefixedTranslation];
});
_c = Plan;
var _c;
$RefreshReg$(_c, "Plan");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;