// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/plans/Plans.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/plans/Plans.tsx");
}
// REMIX HMR END

import { effect, useSignal } from '@preact/signals-react';
import clsx from 'clsx';
import { Plan as PlanComponent } from './Plan';
import styles from './Plans.module.css';
import { planIsBestValue } from 'lib/plan-is-best-value';
// Intervals ordered from smallest to largest
const intervalOrder = ['day', 'week', 'month', 'year'];
function handlePlanSelect(selectedPlan, onPlanSelect) {
  return function (id) {
    selectedPlan.value = id;
    if (onPlanSelect) {
      onPlanSelect(id);
    }
  };
}
export function Plans({
  className,
  plans,
  onPlanSelect,
  initiallySelectedPlan = ''
}) {
  _s();
  const selectedPlan = useSignal(initiallySelectedPlan);
  // Sorts plans from shortest interval (e.g. day) to longest (e.g. year)
  const intervalSortedPlans = [...plans].sort((a, b) => {
    const aIndex = intervalOrder.findIndex(i => i === a.interval);
    const bIndex = intervalOrder.findIndex(i => i === b.interval);
    return aIndex - bIndex;
  });
  effect(() => {
    selectedPlan.value = initiallySelectedPlan;
  });
  return <div className={clsx(styles.plans, className)} data-testid="plans">
      <ul>
        {intervalSortedPlans.map(plan => <PlanComponent key={plan.id} plan={plan} onSelect={handlePlanSelect(selectedPlan, onPlanSelect)} selected={plan.id === selectedPlan.value} isBestValue={planIsBestValue(plan, plans)} />)}
      </ul>
    </div>;
}
_s(Plans, "PP/2F4nhbv0DYNqAyi1vQLjXUbI=", false, function () {
  return [useSignal];
});
_c = Plans;
var _c;
$RefreshReg$(_c, "Plans");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;